<!-- src/components/Notification.vue -->
<template>
  <div>
    <v-snackbar
      v-for="(notification, index) in notifications"
      :key="index"
      v-model="notification.visible"
      :timeout="notification.timeout"
      :color="notification.color"
      :style="{ top: `${index * 72}px` }"
      :right="true"
      :top="true"
    >
      <v-row>
        <v-col v-if="notification.title">
          <strong>{{ notification.title }}</strong>
          <p>{{ notification.text }}</p>
        </v-col>

      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notifications: [],
    };
  },
  methods: {
    showNotification({ title = '', text = '', color = 'default', timeout = 1000 }) {
      const notification = {
        title,
        text,
        color,
        timeout,
        visible: true,
      };
      this.notifications.push(notification);

      setTimeout(() => {
        notification.visible = false;
        this.removeNotification(notification);
      }, timeout);
    },
    removeNotification(notification) {
      const index = this.notifications.indexOf(notification);
      if (index > -1) {
        this.notifications.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped>
/* Optional: Add custom styles for the notification here */
</style>
