// src/plugins/notification.js
import Vue from 'vue';
import Notification from '@/components/Notification.vue';

const NotificationConstructor = Vue.extend(Notification);

const NotificationPlugin = {
  install(Vue) {
    const instance = new NotificationConstructor();
    instance.$mount(document.createElement('div'));
    document.body.appendChild(instance.$el);

    Vue.prototype.$notify = ({ title, text, color, timeout } = {}) => {
      instance.showNotification({ title, text, color, timeout });
    };
  },
};

export default NotificationPlugin;
