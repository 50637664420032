import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import NotificationPlugin from './plugins/notification';
Vue.config.productionTip = false;
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(NotificationPlugin);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'directions', // This is required if you use the Autocomplete plugin
    installComponents: true,
  },
});
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
