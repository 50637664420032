<template>
  <v-app>
    <!-- global loading screen  -->
    <v-overlay z-index="9999" v-if="globalLoading" style="background-color: #a7a7a72e;">
      <span class="font-weight-black black--text mr-2">cargando</span>
      <v-progress-circular
      :size="15"
        indeterminate
        color="primary darken-2"
      ></v-progress-circular>
    </v-overlay>
    <!-- global loading screen  -->
    <!-- <v-app-bar app v-if="flagLoggedIn" elevation="0"> -->
    <AppBar v-if="flagLoggedIn" />
    <!-- </v-app-bar> -->
    <div class="mt-6"></div>
    <!-- Sizes your content based upon application components -->
    <v-main class="mt-12">
      <!-- Provides the application the proper gutter -->
      <router-view></router-view>
    </v-main>

    <!-- <v-footer app> -->
    <!-- -->
    <!-- </v-footer> -->
  </v-app>
</template>

<script>
import AppBar from './AppBar.vue';
//import { mapState } from 'vuex';
export default {
  name: 'App',
  components: {
    AppBar,
  },
  data: () => ({
    
  }),
  // methods: {
  //   fnOpenSnackBar() {
  //     this.$store.dispatch('setSnackbar', {
  //       show: true,
  //       text: 'Default text snackbar',
  //       color: 'pink',
  //     });
  //   },
  //   fnCloseSnackBar(index) {
  //     this.$store.dispatch('closeSnackbar', index);
  //   },
  // },
  computed: {
    // prettier-ignore
    // bottonMargin(){
    //   let aliveSnackbars = this.snackbars.filter((s) => s.show);
    //   return aliveSnackbars.length;
    // },
    //...mapState(['snackbars']
    globalLoading() {
      return this.$store.state.globalLoading;
    },
    flagLoggedIn() {
      return this.$store.state.auth.accessToken ? true : false;
    },
  },
};
</script>
<style>
table > tbody > tr > td {
  font-size: 11px !important;
}
.v-alert {
  position: fixed;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
/* prettier-ignore */
.v-overlay__scrim{
  display: none!important;
}
</style>
