import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './../store';

/**
 * Función para verificación de rutas en autenticación
 */
/**
 * Función para verificación de rutas en autenticación
 */
async function isLoggedIn(to, from, next) {
  store;
  store.state.globalLoading = true;

  try {
    const logged = await store.dispatch('auth/authenticate');
    if (logged) {
      /**redireccionamiento segun rol de usuario, es super usuario */
      if (logged.usuario.rol_nombre == 'Administrador') {
        console.log('router login parametizacion: es administrador');
        
        //        console.log('es admin');
        next();
      } else {
        /**redireccionamiento segun rol de usuario, es adaministrativo */
        let json_permisos = JSON.parse(logged.usuario.permisos_modulos);

        /**parametrizacion segun persmisos asignados */
        store.state.permisos = json_permisos;
        /**
         * fnValidarPermiso(permiso) {
              if (
                this.$store.state.auth.payload.roles_de_usuario.flag_es_super_usuario ==
                1
              ) {
                return true;
              } else {
                let es_un_modulo_valido = this.$store.state.permisos.findIndex(
                  (allowed_modulos) => allowed_modulos == permiso
                );
                return es_un_modulo_valido;
              }
            },

          v-if="fnValidarPermiso('crear usuario') != -1"

          :disabled="fnValidarPermiso('editar usuario') != -1 ? false : true"
          :dark="fnValidarPermiso('editar usuario') != -1 ? true : false"
         * 
        */

        console.log('json_permisos', json_permisos);
        console.log('to.path.toString()', to.path.toString());
        let es_un_modulo_valido = json_permisos.findIndex(
          (allowed_modulos) => allowed_modulos == to.path.toString()
        );

        //console.log('es_un_modulo_valido',es_un_modulo_valido);
        // let es_un_modulo_valido = json_permisos.findIndex(
        //   (allowed_modulos) => allowed_modulos.url == to.path.toString()
        // );
        let isVisita = false;

        /**mostrar vistas internas de vehiculo actualmente en planta */

        // let isReportesAllowed = json_permisos.findIndex(
        //   (allowed_modulos) => allowed_modulos == '/reportes'
        // );

        // /**mostrar vistas internas de reportes */
        // if (isReportesAllowed != -1) {
        //   if (
        //     to.path.substring(0, 44) ==
        //     '/detalle-de-ingreso-y-salida-de-transportes/'
        //   ) {
        //     isVisita = true;
        //   }
        // }

          if (
            to.path.substring(0, 16) ==
            '/detalle-gestion'
          ) {
            isVisita = true;
          }

        //console.log('to.path',to.path);
        //console.log('no es admin');
        if (to.path != '/') {
          //console.log('to path no es para /');
          //permitir ir a visita detalle o a cualquier otra ruta permitida
          if (es_un_modulo_valido != -1 || isVisita == true) {
            next();
          } else {
            //console.log('Acceso denegado ' + es_un_modulo_valido);
            next('/');
          }
        } else {
          //console.log('to path si es para /');
          next();
        }
      }

      store.state.globalLoading = false;
    }
  } catch (e) {
    if (e.code && e.code === 403) {
      next('/');
      store.state.globalLoading = false;
    } else {
      store.state.globalLoading = false;
      next('/login');
    }
  } finally {
    store.commit('auth/unsetAuthenticatePending');
  }
}
/**
 * FIN
 */
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'menuPrincipal',
    component: () =>
      import(/* webpackChunkName: "inicio" */ '../views/menuPrincipal.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/login.vue'),
  },
  {
    path: '/seleccionar-pais',
    name: 'seleccionarPais',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "seleccionarpais" */ '../views/selectPais.vue'
      ),
  },
  {
    path: '/precalificaciones-incompletas',
    name: 'precalificacionesIncompletas',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "precalifucacionesIncompletas" */ '../views/precalificaciones/precalificacionesIncompletas.vue'
      ),
  },
  {
    path: '/precalificaciones-completadas',
    name: 'precalificacionesCompletadas',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "precalifucacionesIncompletas" */ '../views/precalificaciones/precalificacionesCompletadas.vue'
      ),
  },
  {
    path: '/gestiones-en-revision',
    name: 'gestionesEnRevision',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "gestiones en revision" */ '../views/gestiones/gestionesEnRevision.vue'
      ),
  },
  {
    path: '/detalle-gestion/:id?/:filtro?',
    name: 'detalleGestion',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "detalle gestion" */ '../views/gestiones/detalleGestion.vue'
      ),
  },
  {
    path: '/gestiones-con-pendientes',
    name: 'gestionesConPendientes',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "gestiones con pendientes" */ '../views/gestiones/gestionesConPendientes.vue'
      ),
  },
  {
    path: '/gestiones-con-rectificaciones',
    name: 'gestionesConRectificaciones',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "gestiones con rectificaciones" */ '../views/gestiones/gestionesConRectificaciones.vue'
      ),
  },
  {
    path: '/revisar-rectificaciones',
    name: 'revisarRectificaciones',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "revisar rectificaciones" */ '../views/gestiones/revisarRectificaciones/revisarRectificaciones.vue'
      ),
  },
  {
    path: '/gestiones-con-compromiso-de-pago',
    name: 'gestionesCompromisoPago',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "gestiones con compromiso de pago" */ '../views/gestiones/gestionesConCompromisoDePago.vue'
      ),
  },
  {
    path: '/documentos-pendientes-con-compromiso-de-pago',
    name: 'gestionesConDocumentosPendientesConCompromisoDePago',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "gestiones con compromiso de pago" */ '../views/gestiones/gestionesConDocumentosPendientesConCompromisoDePago.vue'
      ),
  },
  {
    path: '/revisar-compromiso-pago/:id?',
    name: 'revisarCompromisoPago',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "revision para aprobar o rechazar compromiso de pago" */ '../views/gestiones/revisarCompromisoPago/aprobarRechazarCompromisoPago.vue'
      ),
  },
  {
    path: '/gestiones-listas-para-aprobacion',
    name: 'gestionesListarAprobacion',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "gestiones listas para aprobacion" */ '../views/gestiones/listasParaAprobacion/gestionesListasParaAprobacion.vue'
      ),
  },
  {
    path: '/aprobacion-credito/:id?',
    name: 'aprobacionEvaluacion',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "aprobar o rechazar evaluacion" */ '../views/gestiones/listasParaAprobacion/aprobarRechazarCredito.vue'
      ),
  },
  {
    path: '/gestiones-concluidas',
    name: 'gestionesConcluidas',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "gestiones concluidas" */ '../views/gestiones/gestionesConcluidas.vue'
      ),
  },
  {
    path: '/listado-eventos',
    name: 'listadoEventos',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "listado de eventos" */ '../views/gestiones/listadoEventos.vue'
      ),
  },
  {
    path: '/seguimiento-gestion',
    name: 'seguimientoGestion',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "seguimiento de gestiones" */ '../views/gestiones/seguimientoGestion.vue'
      ),
  },
  {
    path: '/documentos-pendientes',
    name: 'documentosPendientesCompromisoPago',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "documentos pendientes con compromiso de pago" */ '../views/gestiones/documentosPendientesConCompromisoDePago/documentosPendientesCompromisoPago.vue'
      ),
  },
  {
    path: '/documentos-pendientes-revision',
    name: 'documentosPendientesCompromisoPagoRevision',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "revision complemento de documento pendiente con compromiso de pago" */ '../views/gestiones/documentosPendientesConCompromisoDePago/revisionComplemento.vue'
      ),
  },
  {
    path: '/agentes-de-comercio',
    name: 'agentesDeComercio',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "agentes de comercio" */ '../views/agentesDeComercio/agentesDeComercio.vue'
      ),
  },
  {
    path: '/usuarios-administrativos',
    name: 'usuariosAdministrativos',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "usuarios administrativos" */ '../views/usuariosAdministrativos/usuariosAdministrativos.vue'
      ),
  },
  {
    path: '/reportes-eventos-por-gestion',
    name: 'reporteEventosPorGestion',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "reporte eventos por gestion" */ '../views/reportes/reporteEventoPorGestion.vue'
      ),
  },
  {
    path: '/reportes-contratos-completos-incompletos',
    name: 'reporteContratosCompletosIncompletos',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "reporte de contratos completos e incompletos" */ '../views/reportes/reportesDeContratos.vue'
      ),
  },
  {
    path: '/reportes',
    name: 'reportes-del-sistema',
    beforeEnter: isLoggedIn,
    component: () =>
      import(
        /* webpackChunkName: "reportes del sistema" */ '../views/reportes/reportes.vue'
      ),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_URL,
  routes,
});

export default router;
