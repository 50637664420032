import Vue from 'vue';
import Vuex from 'vuex';
import { FeathersVuex } from '../feathers-client';
import auth from './store.auth';

Vue.use(Vuex);
Vue.use(FeathersVuex);

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /.js$/
);
const servicePlugins = requireModule
  .keys()
  .map((modulePath) => requireModule(modulePath).default);

const store = new Vuex.Store({
  state: {
    globalMonth: new Date().toISOString().substr(0, 7),
    globalMonthTo: new Date().toISOString().substr(0, 7),
    store_modulos: [],
    snackbars: [],
    globalLoading: false,
    revision_contrato_deudor: null,
    revision_complementos_deudor: null,
    revision_evaluacion_deudor: null,
    revision_referencias_deudor: null,
    /** */
    modulos: [
      {
        icono: 'mdi-clipboard-edit-outline',
        titulo: 'Precalificaciones incompletas',
        url: '/precalificaciones-incompletas',
        tipo: 'menuPrincipalTopSection'
      },
      {
        icono: 'mdi-clipboard-edit',
        titulo: 'Precalificaciones completas',
        url: '/precalificaciones-completadas',
        tipo: 'menuPrincipalTopSection'
      },
      {
        icono: 'mdi-clipboard-text-play',
        titulo: 'En revisión',
        url: '/gestiones-en-revision',
        tipo: 'menuPrincipalTopSection'
      },
      {
        icono: 'mdi-clipboard-text-clock',
        titulo: 'Con pendientes',
        url: '/gestiones-con-pendientes',
        tipo: 'menuPrincipalTopSection'
      },
      {
        icono: 'mdi-clipboard-text-clock',
        titulo: 'Con rectificaciones',
        url: '/gestiones-con-rectificaciones',
        tipo: 'menuPrincipalTopSection'
      },
      {
        icono: 'mdi-clipboard-account',
        titulo: 'Con compromiso de pago',
        url: '/gestiones-con-compromiso-de-pago',
        tipo: 'menuPrincipalTopSection'
      },
      {
        icono: 'mdi-clipboard-check',
        titulo: 'Para aprobación',
        url: '/gestiones-listas-para-aprobacion',
        tipo: 'menuPrincipalTopSection'
      },
      {
        icono: 'mdi-clipboard-check-multiple-outline',
        titulo: 'Concluidas',
        url: '/gestiones-concluidas',
        tipo: 'menuPrincipalTopSection'
      },
      {
        titulo: 'Documentos pendientes por compromiso de pago',
        descripcion:
          'Listado de evaluaciones que tiene aprobado el compromiso de pago y están pendientes de presentar la documentación',
        url: '/documentos-pendientes-con-compromiso-de-pago',
        icono: 'mdi-clipboard-account-outline',
        tipo: 'menuPrincipalOpcionesAbajo'
      },
      {
        icono: 'mdi-clipboard-flow',
        titulo: 'Seguimiento de gestión',
        descripcion: 'Listado que muestra el estado de cada una de las evaluaciones',
        url: '/seguimiento-gestion',
        tipo: 'menuPrincipalOpcionesAbajo'
      },
      {
        icono: 'mdi-chart-bar',
        descripcion:
            'Listado de precalificaciones que la agente de comercio no ha completado',
        titulo: 'Reportes',
        url: '/Reportes',
        tipo: 'menuPrincipalOpcionesAbajo'
      },
      {
        icono: 'mdi-account-key',
        titulo: 'Agentes de comercio',
        url: '/agentes-de-comercio',
        descripcion:
            'Gestiona los accesos de las agentes de comercio a la app móvil',
        tipo: 'menuPrincipalOpcionesAbajo'
      },
      {
        icono: 'mdi-account-key-outline',
        titulo: 'Usuarios administrativos',
        url: '/usuarios-administrativos',
        descripcion: 'Crea y edita usuarios administrativos',
        tipo: 'menuPrincipalOpcionesAbajo'
      },
      // {
        //   titulo: 'Bitacora',
        //   descripcion: 'Búsqueda de gestiones en el archivo histórico',
        //   url: '',
        //   icono: 'mdi-history',
        // },
    ],
  },
  mutations: {
    SET_SNACKBAR(state, snackbar) {
      state.snackbars = state.snackbars.concat(snackbar);
    },
    CLOSE_SNACKBAR(state, snackbar_index) {
      console.log(state.snackbars);
      console.log('state.snackbars before');
      state.snackbars.splice(snackbar_index, 1);
      console.log('state.snackbars after');
    },
  },
  actions: {
    fnGetBorder() {
      return '#EEFF41';
    },
    setSnackbar({ commit }, snackbar) {
      snackbar.color = snackbar.color || 'green';
      commit('SET_SNACKBAR', snackbar);
    },
    closeSnackbar({ commit }, snackbar_index) {
      console.log(snackbar_index);
      commit('CLOSE_SNACKBAR', snackbar_index);
    },
  },
  modules: {},
  plugins: [...servicePlugins, auth],
});

export default store;
